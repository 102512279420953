import React, { useContext } from "react"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import { toDineroFormatted } from "../../helpers/toDinero"
import { BrinkContext } from "../context/BrinkContext"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 0.1rem solid ${(p) => p.theme.colors.lightGrey};
  margin-top: 2rem;
`

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding-bottom: 0.1rem;
  color: ${(p) => p.theme.colors.black};
`

const GrandTotal = styled(Row)`
  z-index: 1;
  background-color: ${(p) => p.theme.colors.background};

  p {
    color: ${(p) => p.theme.colors.black};
    font-size: 2rem;
    font-variation-settings: "wght" 700;
    border-bottom: none;
    text-transform: uppercase;
    margin-top: 2rem;
  }
`

const Line = styled(Row)`
  flex-direction: row;
  margin: 0;
`

const Label = styled.span`
  font-size: 2rem;
  padding: 0.2rem 0;
`

const Totals = ({ order, ...rest }) => {
  const { t } = useTranslation("translations")
  const { currentStore } = useContext(BrinkContext)
  const { currencyUnit } = currentStore
  const shipping = order.orderLines.filter((item) => item.type === "shipment")
  const subTotal = order.orderLines
    .filter((item) => item.type !== "shipment")
    .reduce((sum, a) => sum + a.totalAmount, 0)

  return (
    <Container {...rest}>
      {subTotal && (
        <Line>
          <Label>{t("Subtotal")}</Label>
          <Label>{toDineroFormatted(subTotal, currencyUnit)}</Label>
        </Line>
      )}
      {shipping.length > 0 && (
        <Line>
          <Label>{t("Shipping")}</Label>
          <Label>
            {toDineroFormatted(shipping[0].price || 0, currencyUnit)}
          </Label>
        </Line>
      )}
      {order.orderDiscountAmount !== 0 && (
        <Line>
          <Label>{t("Discount")}</Label>
          <Label>
            - {toDineroFormatted(order.orderDiscountAmount, currencyUnit)}
          </Label>
        </Line>
      )}
      <Line>
        <Label>{t("Tax")}</Label>
        <Label>
          {toDineroFormatted(order.orderTaxAmount || 0, currencyUnit)}
        </Label>
      </Line>
      <GrandTotal>
        <p>{t("Total")}</p>
        <p>
          {toDineroFormatted(order.orderAmountWithDiscount || 0, currencyUnit)}
        </p>
      </GrandTotal>
    </Container>
  )
}

export default Totals
