import React, { useContext } from "react"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import { BrinkContext } from "../context/BrinkContext"
import { MEDIA_MIN_MEDIUM } from "../../constants"
import { toDinero, toFormat } from "../../helpers/toDinero"

const Container = styled.div`
  color: ${(p) => p.theme.colors.black};
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  margin-bottom: 1.2rem;
`

const Wrapper = styled.div`
  display: flex;
  width: calc(100% - 12.2rem);
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  ${MEDIA_MIN_MEDIUM} {
    width: calc(100% - 11.4rem);
  }
`

const Name = styled.h2`
  flex: 1 1 50%;
  margin: 0;
  font-variation-settings: "wght" 500;
  font-size: 2rem;
  text-align: left;
`

const PriceContainer = styled.div`
  flex: 1 1 30%;
  display: flex;
  font-size: 2rem;
  line-height: 2.4rem;
  align-items: start;
  justify-content: flex-end;
  text-align: right;
`

const CurrentPrice = styled.p`
  width: 100%;
  font-size: 2rem;
  line-height: 2.4rem;
  margin: 0;
`

const ChangeQty = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.6rem;
`

const Qty = styled.div`
  margin: 0 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(p) => p.theme.colors.black};
  font-weight: 450;
  font-size: 2rem;
  line-height: 2.4rem;
`

const QtyButton = styled.button`
  cursor: pointer;
  width: 2.4rem;
  height: 2.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0.1rem solid ${(p) => p.theme.colors.lightGrey};
  padding: 0;
  background: ${(p) => p.theme.colors.lightGrey};
  border-radius: 50%;
  color: ${(p) => p.theme.colors.black};
  display: flex;

  span {
    margin-top: 0.4rem;
    font-size: 3.5rem;
    font-variation-settings: "wght" 150;
    line-height: 1.8rem;
  }
`

const OriginalPrice = styled.p`
  margin: 0;
  color: #c4c4c4;
  text-decoration: line-through;
`

const ImageContainer = styled.div`
  display: block;
  width: 12.2rem;
  padding: 0;
  margin-right: 1.2rem;

  ${MEDIA_MIN_MEDIUM} {
    width: 11.4rem;
  }
`

const Image = styled.img`
  width: 100%;
  display: block;
`

const Box = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const Price = styled.div`
  display: flex;
  flex-direction: column;
`

const Item = ({ item, showControls, ...other }) => {
  const { t } = useTranslation("translations")
  const {
    cart,
    currentStore,
    plusOneProductVariantToCart,
    minusOneProductVariantToCart,
    setIsLoading,
    languageCode
  } = useContext(BrinkContext)

  if (item.type === "shippingOption") return false
  const { id, imageUrl, attribute, quantity, isGift } = item
  const { displayName } = attribute

  const productName = (displayName[languageCode] || displayName.en).replace(
    "\\n",
    " "
  )
  const discount = cart.discounts.productDiscounts.find((d) => d.id === id)
  const priceDinero = toDinero(
    item.price[currentStore.currencyUnit],
    currentStore.currencyUnit
  )
  let discountDinero = null
  if (discount) {
    discountDinero = toDinero(
      discount.discountAmount[currentStore.currencyUnit],
      currentStore.currencyUnit
    )
  }
  const price = discountDinero
    ? toFormat(priceDinero.subtract(discountDinero))
    : null
  const originalPrice = toFormat(priceDinero)
  const isFree =
    item.price[currentStore.currencyUnit] -
      discount?.discountAmount[currentStore.currencyUnit] ===
    0

  return (
    <Container {...other}>
      <Wrapper>
        <Box>
          <Name>{productName}</Name>
          <PriceContainer>
            {price ? (
              <Price>
                <CurrentPrice>{isFree ? t("Free") : price}</CurrentPrice>
                <OriginalPrice>{originalPrice}</OriginalPrice>
              </Price>
            ) : (
              <CurrentPrice>{originalPrice}</CurrentPrice>
            )}
          </PriceContainer>
        </Box>

        {showControls && !isGift && (
          <ChangeQty>
            <QtyButton
              onClick={() => {
                setIsLoading(true)
                minusOneProductVariantToCart(id)
              }}
              onKeyDown={(event) => {
                if (event.key !== "Enter") return false
                minusOneProductVariantToCart(id)
                setIsLoading(true)
              }}
              tabIndex={0}
              aria-label="Increase quantity"
            >
              <span>-</span>
            </QtyButton>
            <Qty>{quantity}</Qty>
            <QtyButton
              onClick={() => {
                plusOneProductVariantToCart(id)
                setIsLoading(true)
              }}
              onKeyDown={(event) => {
                if (event.key !== "Enter") return false
                plusOneProductVariantToCart(id)
                setIsLoading(true)
              }}
              tabIndex={0}
              aria-label="Decrease quantity"
            >
              <span>+</span>
            </QtyButton>
          </ChangeQty>
        )}
      </Wrapper>
      <ImageContainer>
        <Image src={imageUrl} alt={productName} />
      </ImageContainer>
    </Container>
  )
}

export default Item
