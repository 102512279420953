import Dinero from "dinero.js"

export const toDinero = (amount, currencyUnit) => {
  return new Dinero({ amount: amount, currency: currencyUnit })
}

export const toDineroFormatted = (amount, currencyUnit) => {
  return new Dinero({ amount: amount, currency: currencyUnit })
    .setLocale("de")
    .toFormat("USD0,0")
    .replace(".", " ")
}

export const toFormat = (amount) => {
  return amount.setLocale("de").toFormat("USD0,0").replace(".", " ")
}
