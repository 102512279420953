import { useLocation } from "@reach/router"
import * as Sentry from "@sentry/react"
import dayjs from "dayjs"
import { navigate } from "gatsby"
import React, { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import styled, { ThemeProvider } from "styled-components"
import Item from "../components/cart/Item"
import Header from "../components/checkout/Header"
import { BrinkContext } from "../components/context/BrinkContext"
import * as events from "../components/context/utils/events"
import Totals from "../components/order/Totals"
import { MEDIA_MIN_LARGE, MEDIA_MIN_MEDIUM } from "../constants"
import { theme } from "../theme"

const Container = styled.div`
  max-width: 75rem;
  margin: 8rem auto 5rem;
  padding: 0 1.2rem;

  ${MEDIA_MIN_LARGE} {
    width: 50%;
  }
`

const PageTitle = styled.h1`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  font-size: 3rem;
  text-transform: initial;
  margin: 0;
  padding-top: 4rem;
`

const PageSubTitle = styled.p`
  text-align: left;
  padding: 0;
  font-size: 2rem;
  text-transform: initial;
  margin: 3rem 0;

  ${MEDIA_MIN_LARGE} {
    font-size: 2rem;
  }

  a {
    color: ${(p) => p.theme.colors.black};
  }
`

const Border = styled.div`
  ${MEDIA_MIN_LARGE} {
    border: 0.1rem solid ${(p) => p.theme.colors.grey};
  }
`

const GeneralInfo = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  max-width: 75rem;
  margin: 2rem 0 0;
  color: ${(p) => p.theme.colors.black};
  border-top: 0.1rem solid ${(p) => p.theme.colors.grey};
  padding: 2rem 0;

  ${MEDIA_MIN_LARGE} {
    padding: 0 1.2rem 2rem;
    border: none;
  }
`

const BillingInfo = styled.div`
  border-top: 0.1rem solid ${(p) => p.theme.colors.grey};
  padding: 0 0 2rem;
  display: flex;
  flex-wrap: wrap;

  ${MEDIA_MIN_LARGE} {
    margin: 0 1.2rem 2rem;
  }

  > div {
    width: 100%;

    ${MEDIA_MIN_LARGE} {
      width: 50%;
    }
  }

  h3 {
    font-size: 2rem;
    color: ${(p) => p.theme.colors.darkGrey};
    line-height: 2.4rem;
    margin-bottom: 1rem;
    text-transform: initial;
  }

  p {
    margin: 0;
    font-size: 2rem;
    color: ${(p) => p.theme.colors.black};
    line-height: 2.4rem;
  }
`

const Box = styled.div`
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: space-between;
`

const Title = styled.p`
  font-size: 2rem;
  display: inline-block;
  margin: 0;
  line-height: 2.4rem;

  ${MEDIA_MIN_MEDIUM} {
    display: block;
  }
`

const Value = styled.p`
  font-size: 2rem;
  display: inline-block;
  margin: 0;
  line-height: 2.4rem;
`

const Summary = styled.div`
  position: relative;
  border-top: 0.1rem solid ${(p) => p.theme.colors.grey};
  padding: 0;

  ${MEDIA_MIN_LARGE} {
    margin: 0 1.2rem;
  }

  h3 {
    position: absolute;
    background: ${(p) => p.theme.colors.white};
    top: -2.1rem;
    padding: 0 1rem;
    left: 50%;
    transform: translate(-50%, 0);
    font-size: 1.4rem;
    white-space: nowrap;

    ${MEDIA_MIN_MEDIUM} {
      left: auto;
      transform: translate(0, 0);
    }
  }
`

const Items = styled.div`
  width: 100%;
  margin: 2rem 0;
`

const CartTotals = styled(Totals)`
  border-top: 0.1rem solid ${(p) => p.theme.colors.grey};
  padding-top: 2rem;
`

const SuccessPage = ({ pageContext }) => {
  const { t } = useTranslation("translations")
  const location = useLocation()
  const [order, setOrder] = useState(null)
  const {
    discountCode,
    currentStore,
    deleteDiscountCode,
    deleteDiscountCodePopup,
    listName,
    getOrderConfirmation,
    closeCart
  } = useContext(BrinkContext)
  const useMountEffect = (fun) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return useEffect(fun, [])
  }

  const query = new URLSearchParams(location.search)
  const orderId = query.get("orderId")
  const signature = query.get("signature")

  const handleTracking = (order) => {
    try {
      events.purchase({
        order,
        email: order.email ?? order.billingAddress.email,
        discountCode,
        cart: order.cart,
        currentStore,
        listName
      })
      deleteDiscountCode()
      deleteDiscountCodePopup()
    } catch (error) {
      console.error(error)
      Sentry.captureException(error)
    }
  }

  const {
    order: stateOrder,
    billingAddress,
    cart,
    signature: stateSignature,
    orderId: stateOrderId
  } = location.state ?? {}

  useMountEffect(() => {
    const run = async () => {
      let order
      const fetchSignature = signature ?? stateSignature
      const fetchOrderId = orderId ?? stateOrderId
      if (fetchOrderId && fetchSignature) {
        order = await getOrderConfirmation(fetchOrderId, fetchSignature).catch(
          (error) => {
            console.error("get order full error:", error)
            return navigate("/")
          }
        )
      } else {
        order = {
          ...stateOrder,
          person: billingAddress,
          cart: cart
        }
      }
      closeCart()
      setOrder(order)
      handleTracking(order)
    }
    run()
  })
  if (!order) return null
  return (
    <ThemeProvider theme={theme(pageContext.sanityTheme)}>
      <Header />
      <Container>
        <PageTitle>
          {t("Thanks for your order, ")}
          {order.billingAddress.givenName}
        </PageTitle>
        <PageSubTitle>
          {t("An order confirmation has been sent to")}{" "}
          {order.billingAddress.email}{" "}
          {t(
            "If you have any questions, don’t hesitate to get in contact with us"
          )}{" "}
          <a href="mailto:customercare@comis.com">customercare@comis.com</a>
        </PageSubTitle>
        <Border>
          <GeneralInfo>
            <Box>
              <Title>{t("Order number")}:</Title>
              <Value>{`#${order.reference}`}</Value>
            </Box>
            <Box>
              <Title>{t("Date")}:</Title>
              <Value>{dayjs().format("YYYY-MM-DD")}</Value>
            </Box>
          </GeneralInfo>
          <BillingInfo>
            <div>
              <h3>{t("Billing info")}</h3>
              <p>{order.paymentMethod}</p>
              <p>
                {order.billingAddress.givenName}{" "}
                {order.billingAddress.familyName}
              </p>
            </div>
            <div>
              <h3>{t("Shipping address")}</h3>
              <p>
                {order.billingAddress.givenName}{" "}
                {order.billingAddress.familyName}
              </p>
              <p>{order.billingAddress.streetAddress}</p>
              <p>
                {order.billingAddress.postalCode} {order.billingAddress.city}
              </p>
              <p>{order.billingAddress.country}</p>
            </div>
          </BillingInfo>
          <Summary>
            <Items>
              {order.cart.cartItems.map((item) => {
                const orderLine = order.orderLines.find(
                  (orderLine) => orderLine.productId === item.id
                )
                return (
                  orderLine && (
                    <Item
                      item={{ ...item, quantity: orderLine.quantity }}
                      key={item.id}
                      showControls={false}
                    />
                  )
                )
              })}
            </Items>
            <CartTotals order={order} />
          </Summary>
        </Border>
      </Container>
    </ThemeProvider>
  )
}

export default SuccessPage
